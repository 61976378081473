import {
  Content,
  ContentBox,
  Description,
  FooterWrapper,
  Links,
  Title,
  Text,
} from "./styled";

export const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <ContentBox>
        <Content>
          <Title>㈜나인투랩스 NinetwoLabs Inc.</Title>
          <Description>
            사업자등록번호 : 880-81-01796 | 대표이사 : 박불이세
          </Description>
          <Description>문의 : contact@ninetwolabs.com</Description>
          <Description>
            통신판매업신고번호 : 2023-서울마포-2372{" "}
            <Text href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=8808101796" target="_blank" rel="noreferrer">
              사업자정보확인
            </Text>
          </Description>
          <Description>
            게임제작업등록번호 : 마포구청 제2023-000009호
          </Description>
          <Description>
            게임배급업등록번호 : 마포구청 제2023-000006호
          </Description>
          <Description>
            4F, 37, Worldcupbuk-ro 4-gil, Mapo-gu, Seoul, 03992, Korea
          </Description>
          <Description>
            ⓒ 2025 NinetwoLabs Inc. 모든 권리 보유. 일부 게임 또는 서비스의 경우
            한정 권리 보유.
          </Description>
        </Content>
        <Links>
          <Text
            href="https://ninetwolabs.com/privacy.html"
            target="_blank"
            rel="noreferrer"
          >
            개인정보 처리방침
          </Text>
          <Text
            href="https://ninetwolabs.com/terms.html"
            target="_blank"
            rel="noreferrer"
          >
            이용약관
          </Text>
        </Links>
      </ContentBox>
    </FooterWrapper>
  );
};
